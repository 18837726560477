import Home from '../pages/home/index';
import Introduce from '../pages/introduce/index';
import Product from '../pages/product/index';
import Plan from '../pages/plan/index';
// import Contact from '../pages/contant/index';

let routes = [
  {
    path: '/',
    component: <Home/>,
    exact:true,
    name: '首页',
    name1: 'Home'
  },
  {
    path: '/home',
    component: <Home/>,
    exact:false,
    name: '首页',
    name1: 'Home'
  },
  {
    path: '/introduce',
    component: <Introduce/>,
    exact:false,
    name: '企业简介',
    name1:"Company"
  },
  {
    path: '/product',
    component: <Product/>,
    exact:false,
    name: '产品',
    name1:"Product"
  },
  {
    path: '/plan',
    component: <Plan/>,
    exact:false,
    name: '系统方案',
    name1:"Project"
  },
  // {
  //   path: '/contant',
  //   component: Contact,
  //   exact:false,
  //   name: '联系我们',
  //   name1:"Contact"
  // }

];
export default routes;
